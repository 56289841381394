import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Lottie from 'lottie-react-web';

import { Delete, OpenInNewRounded } from '@mui/icons-material';
import { Button, IconButton, LinearProgress } from '@mui/material';

import CheckAnimation from 'animation/check.json';
import useStyles from './styles';

interface FileProps {
  fileName: string;
  value: string;
  completed?: boolean;
  percentage?: number;
  onUpload: (files: FileList) => Promise<void>;
  onRemove: () => Promise<void>;
  disabled?: boolean;
  size?: number;
  accept?: string;
}

const File = ({
  fileName,
  value,
  completed,
  percentage,
  onUpload,
  onRemove,
  disabled,
  size,
  accept,
}: FileProps) => {
  const styles = useStyles();
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileDialog = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (disabled) return;

    fileInputRef.current?.click();
  };

  const handleOnDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (disabled) return;

    const { files } = event.dataTransfer;
    if (files.length > 0) {
      handleUpload(files);
    }
  };

  const handleOnChange = () => {
    if (fileInputRef.current?.files) {
      handleUpload(fileInputRef.current.files);
    }
  };

  const handleUpload = async (files: FileList) => {
    try {
      setIsUploading(true);
      await onUpload(files);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (disabled) return;

    await onRemove();
  };

  return (
    <div
      className={classNames(styles.container, value ? styles.highlight : '')}
      onDrop={handleOnDrop}
      onDragOver={(event) => event.preventDefault()}
      onClick={openFileDialog}
    >
      <input
        ref={fileInputRef}
        className={styles.fileInput}
        type="file"
        data-testid="file-input"
        multiple
        onChange={handleOnChange}
        size={size}
        accept={accept}
      />
      {fileName ? (
        <div className={styles.innerContainer}>
          <div className={styles.fileName}>
            <div>{fileName}</div>
            <IconButton size="small" onClick={handleRemove}>
              <Delete className={styles.iconDelete} />
            </IconButton>
          </div>
          <div className={styles.download}>
            <Button
              color="primary"
              variant="text"
              size="small"
              className={styles.downloadButton}
              href={value ? value.toString() : ''}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              <OpenInNewRounded className={styles.downloadIcon} /> <div>Datei anzeigen</div>
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.innerContainer}>
          <div className={styles.text}>
            Legen Sie Dateien hier ab oder klicken Sie, um sie hochzuladen.
          </div>
          {isUploading && (
            <LinearProgress variant="determinate" value={percentage} className={styles.progress} />
          )}
        </div>
      )}
      {completed ? (
        <div className={styles.check}>
          <Lottie
            width={30}
            height={30}
            options={{
              animationData: CheckAnimation,
              loop: false,
            }}
            speed={2}
          />
        </div>
      ) : null}
    </div>
  );
};

export default File;
