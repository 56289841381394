import { BackendCustomerProfileForUpdate } from 'utils/backendTypes';
import { InitialValues } from 'context/customer-context/types';
import { serializeCompanyToBackend, serializePersonToBackend } from './index';

/**
 * Serialize form values into a structure that can be used in customer profile bulk update. All the `values` should be
 * previously validated.
 * @param values: form values from formik.
 */
export const serializeCustomerProfileToBackendUpdate = (
  values: InitialValues,
  jurisdictionId: number | '',
  mainCompanyStatus: string,
): BackendCustomerProfileForUpdate => {
  const business = values.companies.map((v) => serializeCompanyToBackend(v));
  const persons = values.people.map((v) =>
    serializePersonToBackend(v, undefined, mainCompanyStatus),
  );
  return {
    customer_profile: {
      cooperation_partner: jurisdictionId,
    },
    business,
    persons,
  };
};
