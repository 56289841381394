import { useCallback, useState, useMemo, memo, useEffect, useRef } from 'react';
import { Formik, useFormikContext, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import { Level } from 'utils/commonTypes/types';
import {
  getRoleArrayFromPersonCompany,
  getLocaleAllocation,
  getShareRoles,
  removeFromContext,
} from 'utils/utils';
import { handleBackendErrors } from 'utils/handleBackendErrors';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import { LANGUAGE } from 'constants/language';
import { useCustomerData } from 'context/customer-context';
import { useDataContext } from 'context/data-context';
import {
  EKLegalForm,
  EKLegalFormLabel,
  FreelanceLegalForm,
  FreelanceLegalFormLabel,
  GbRLegalForm,
  PartGLegalForm,
  PartGmbBLegalForm,
} from 'constants/legalForms';
import {
  Person,
  Company,
  RefBeneficialOwner,
  CompanyFormik,
  PersonFormik,
  CompanyFormValues,
} from 'context/customer-context/types';
import {
  returnBooleanForBackend,
  serializePersonToContext,
  getOwnerRef,
  getBeneficialOwnerRef,
  serializeCompanyToContext,
  getLegalRepresentativeRef,
  getLoanApplicantRef,
} from 'utils/dataHandlers';
import {
  BackendNewPersonData,
  BackendExistingData,
  BackendNewBusinessData,
} from 'utils/backendTypes';
import { BLOCK_STATUS_NOT_REVIEWED } from 'constants/reviewBlocks';
import { createRelatedPathsModal } from 'components/StaticBlocks/utils/reviewFunctions';
import { ModalType } from '../../types';
import { validationSchema } from './validationSchema';
import Modal from './modal';

interface CreateModalProps {
  onClose: () => void;
  open: boolean;
  level: Level;
  modalType: ModalType;
  type: 'OW' | 'BO';
  parentCompany?: number;
  parentName?: string;
  secondLevelRefID?: number;
  thirdLevelRefID?: number;
  afterCreate?: (item: CompanyFormik | PersonFormik) => void;
  mainCompanyFormik?: CompanyFormValues | null;
  isReviewing?: boolean;
  isEkOrFreelancer: boolean;
}

const getCurrentLegalForm = (legalForm?: string) => {
  if (legalForm) {
    if (legalForm === EKLegalFormLabel) return 'eK';
    if (legalForm === FreelanceLegalFormLabel) return 'Fb';
    if (legalForm === GbRLegalForm) return 'GbR';
    if (legalForm === PartGLegalForm) return 'PartG';
    if (legalForm === PartGmbBLegalForm) return 'PartGmbB';
  }
  return undefined;
};

const CreateModal = memo(
  ({
    onClose,
    open,
    level,
    modalType,
    parentCompany,
    secondLevelRefID = undefined,
    thirdLevelRefID = undefined,
    parentName = undefined,
    type,
    mainCompanyFormik = null,
    isReviewing = false,
    afterCreate = () => {},
    isEkOrFreelancer,
  }: CreateModalProps) => {
    const {
      values: valuesMainFormik,
      setFieldValue: setFieldValueFormik,
      resetForm,
    } = useFormikContext<FormikValues>();
    const formikRef = useRef(null);
    const [loadingCreating, setLoadingCreating] = useState(false);
    const [sharesError, setSharesError] = useState(false);
    const [personOrCompanyId, setPersonOrCompanyId] = useState<string>('');
    const [creationValues, setCreationValues] = useState<Person | Company | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const {
      companies,
      customerProfileId,
      mainCompany,
      people,
      currentLegalForm,
      refsBeneficialOwners,
      refsLegalRepresentatives,
      refsOwners,
      refsLoanApplicants,
      setCompanies,
      setPeople,
      setRefsBeneficialOwners,
      setRefsLegalRepresentatives,
      setRefsOwners,
      setRefsLoanApplicants,
    } = useCustomerData();
    const { loanApplication } = useDataContext();

    const currentLF = getCurrentLegalForm(currentLegalForm?.label);

    useEffect(() => {
      if (isReviewing && creationValues && personOrCompanyId && loanApplication)
        createRelatedPaths(creationValues);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creationValues, personOrCompanyId]);

    const formikInitialValues = useMemo(
      () => ({
        createOrOvertake: '',
        personOrCompany: '',
        personCompanySelection: null,
        salutation: '',
        title: '',
        isBeneficialOwner:
          currentLF === EKLegalForm || currentLF === FreelanceLegalForm ? 'yes' : '',
        isLegalRepresentative:
          type === 'BO'
            ? level === 1 && (currentLF === EKLegalForm || currentLF === FreelanceLegalForm)
              ? 'yes'
              : ''
            : currentLF === EKLegalForm || currentLF === FreelanceLegalForm
              ? 'yes'
              : '',
        isLoanApplicant:
          type === 'BO'
            ? level === 1 && (currentLF === EKLegalForm || currentLF === FreelanceLegalForm)
              ? 'yes'
              : ''
            : currentLF === EKLegalForm || currentLF === FreelanceLegalForm
              ? 'yes'
              : '',
        companyName: '',
        legalForm: '',
        allocation:
          type === 'BO'
            ? level === 1 && (currentLF === EKLegalForm || currentLF === FreelanceLegalForm)
              ? '100'
              : ''
            : currentLF === EKLegalForm || currentLF === FreelanceLegalForm
              ? '100'
              : '',
        hgb: '',
        sideDeal: '',
        modalType: type === 'OW' ? 'owner' : 'beneficialOwner',
        currentLegalForm: currentLF,
        level,
        peopleMainFormik: valuesMainFormik.people,
        companiesMainFormik: valuesMainFormik.companies,
      }),
      [currentLF, type, level, valuesMainFormik.people, valuesMainFormik.companies],
    );

    const { people: peopleFormik, companies: companiesFormik } = valuesMainFormik;

    const handleUseExistingPerson = useCallback(
      async (values: any, role: any) => {
        const {
          personCompanySelection,
          allocation,
          sideDeal,
          isLoanApplicant,
          isBeneficialOwner: isBeneficialOwnerFormik,
          isOwner: isOwnerFormik,
          isLegalRepresentative,
        } = values;
        const newRoles = [role];
        let rolesInPerson;
        if (currentLegalForm?.label === GbRLegalForm) {
          if (role !== 'OWP') newRoles.push('OWP');
          newRoles.push('LRP');
          newRoles.push('LAP');
        }
        if (isLoanApplicant === 'yes') newRoles.push('LAP');
        if (isLegalRepresentative === 'yes') newRoles.push('LRP');
        if (isBeneficialOwnerFormik === 'yes' && role !== 'BOP') newRoles.push('BOP');
        if (isOwnerFormik === 'yes' && role !== 'OWP') newRoles.push('OWP');
        let dataBody;
        let personInContext;
        if (personCompanySelection?.groupId === 0) {
          personInContext = people.find((p) => p.id === personCompanySelection?.id);
          const personShares = personInContext?.shares.find((s) => s.parent === parentCompany);
          const { isOwner, isBeneficialOwner } = getShareRoles(
            personShares?.allocation,
            personShares?.sideDeal,
          );
          const { isOwner: isCurrentOwner, isBeneficialOwner: isCurrentBeneficialOwner } =
            getShareRoles(allocation, sideDeal);
          if (!isOwner && isCurrentOwner && role !== 'OWP') newRoles.push('OWP');
          if (!isBeneficialOwner && isCurrentBeneficialOwner && role !== 'BOP')
            newRoles.push('BOP');
          rolesInPerson = getRoleArrayFromPersonCompany(personInContext as Person);
          const dataPerson: BackendNewPersonData = {
            customer_profile_relation: {
              roles: [...rolesInPerson, ...newRoles],
              legitimacy:
                currentLegalForm?.label === GbRLegalForm ||
                currentLegalForm?.label === EKLegalFormLabel ||
                currentLegalForm?.label === FreelanceLegalFormLabel,
            },
            shares: [
              {
                parent: parentCompany,
                allocation: getLocaleAllocation(allocation, LANGUAGE),
              },
            ],
          };

          if (mainCompany && getLocaleAllocation(allocation, LANGUAGE) <= 50 && dataPerson.shares) {
            dataPerson.shares[0].side_deal = returnBooleanForBackend(sideDeal);
          }
          const { data } = await request.patch(
            `${ApiCaptiq.CUSTOMER_PROFILE_PERSON(customerProfileId!)}${personInContext?.id}/`,
            dataPerson,
          );
          dataBody = data;
        } else {
          const dataPerson: BackendExistingData = {
            entity: personCompanySelection?.id,
            customer_profile_relation: {
              roles: newRoles,
              legitimacy:
                currentLegalForm?.label === GbRLegalForm ||
                currentLegalForm?.label === EKLegalFormLabel ||
                currentLegalForm?.label === FreelanceLegalFormLabel,
            },
            shares: [
              {
                parent: parentCompany,
                allocation: getLocaleAllocation(allocation, LANGUAGE),
              },
            ],
          };
          if (mainCompany && getLocaleAllocation(allocation, LANGUAGE) <= 50 && dataPerson.shares) {
            dataPerson.shares[0].side_deal = returnBooleanForBackend(sideDeal);
          }

          const { data } = await request.post(
            `${ApiCaptiq.CUSTOMER_PROFILE(customerProfileId!)}add-existing-person/`,
            dataPerson,
          );
          dataBody = data;
        }
        const personContext = serializePersonToContext(dataBody, mainCompany);
        setCreationValues({ ...personContext, personOrCompany: 'person' });
        setPersonOrCompanyId(personContext.personId as string);
        let formikPersonIndex: number | undefined;
        if (personCompanySelection?.groupId === 0)
          formikPersonIndex = peopleFormik.findIndex((pf: Person) => pf.id === personContext.id);
        const refIndex = formikPersonIndex ?? people.length;
        if (personCompanySelection?.groupId !== 0) {
          setPeople([...people, personContext]);
          afterCreate({ ...personContext, personOrCompany: 'person' });
        }
        if (role === 'OWP') {
          setRefsOwners([...refsOwners, getOwnerRef(personContext, 'person', refIndex)]);
          if (personCompanySelection?.groupId === 0) {
            setFieldValueFormik(`people[${refIndex}].isOwner`, 'yes');
            const personShares = personInContext?.shares.find((s) => s.parent === parentCompany);
            const shareRole = getShareRoles(personShares?.allocation, personShares?.sideDeal);
            const currentShareRole = getShareRoles(allocation, sideDeal);
            if (shareRole.isBeneficialOwner && !currentShareRole.isBeneficialOwner)
              removeFromContext(
                refsBeneficialOwners,
                setRefsBeneficialOwners,
                personCompanySelection?.id,
                true,
              );
            setFieldValueFormik(`people[${refIndex}].shares`, personContext.shares);
          }
          if (getLocaleAllocation(allocation, LANGUAGE) > 25) {
            if (personCompanySelection?.groupId === 0) {
              if (currentLegalForm?.label === GbRLegalForm) {
                if (rolesInPerson && !rolesInPerson.includes('LRP')) {
                  setRefsLegalRepresentatives([
                    ...refsLegalRepresentatives,
                    getLegalRepresentativeRef(personContext, people.length),
                  ]);
                  setFieldValueFormik(`people[${refIndex}].isLegalRepresentative`, 'yes');
                }
                if (rolesInPerson && !rolesInPerson.includes('LAP')) {
                  setRefsLoanApplicants([
                    ...refsLoanApplicants,
                    getLoanApplicantRef(personContext, people.length),
                  ]);
                  setFieldValueFormik(`people[${refIndex}].isLoanApplicant`, 'yes');
                }
              }
              if (rolesInPerson && !rolesInPerson.includes('BOP')) {
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelOne`, 'yes');
                personContext.isBeneficialOwnerLevelOne = 'yes';
                setRefsBeneficialOwners([
                  ...refsBeneficialOwners,
                  getBeneficialOwnerRef(personContext, 'person', refIndex),
                ]);
              }
            } else {
              personContext.isBeneficialOwnerLevelOne = 'yes';
              setRefsBeneficialOwners([
                ...refsBeneficialOwners,
                getBeneficialOwnerRef(personContext, 'person', refIndex),
              ]);
              if (
                currentLegalForm?.label === GbRLegalForm ||
                currentLegalForm?.label === EKLegalFormLabel ||
                currentLegalForm?.label === FreelanceLegalFormLabel
              ) {
                setRefsLegalRepresentatives([
                  ...refsLegalRepresentatives,
                  getLegalRepresentativeRef(personContext, people.length),
                ]);
                setRefsLoanApplicants([
                  ...refsLoanApplicants,
                  getLoanApplicantRef(personContext, people.length),
                ]);
              }
            }
          }
        } else {
          if (level === 1) personContext.isBeneficialOwnerLevelOne = 'yes';
          if (level === 2) personContext.isBeneficialOwnerLevelTwo = 'yes';
          if (level === 3) personContext.isBeneficialOwnerLevelThree = 'yes';
          const boRef = getBeneficialOwnerRef(personContext, 'person', refIndex);
          if (level === 2 || level === 3) {
            const newBORef: RefBeneficialOwner = JSON.parse(
              JSON.stringify(refsBeneficialOwners[secondLevelRefID as number]),
            );
            if (level === 2) {
              newBORef.children?.push(boRef);
              if (personCompanySelection?.groupId === 0) {
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelTwo`, 'yes');
              }
            } else if (newBORef && newBORef.children) {
              const thirdLevelChildren = newBORef.children[thirdLevelRefID as number];
              if (thirdLevelChildren && thirdLevelChildren.children) {
                thirdLevelChildren.children.push(boRef);
              }
              if (personCompanySelection?.groupId === 0) {
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelThree`, 'yes');
              }
            }
            setFieldValueFormik(`people[${refIndex}].shares`, personContext.shares);
          } else {
            setRefsBeneficialOwners([...refsBeneficialOwners, boRef]);
            if (personCompanySelection?.groupId === 0) {
              setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
              setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelOne`, 'yes');
            }
            if (getLocaleAllocation(allocation, LANGUAGE) > 0) {
              if (personCompanySelection?.groupId === 0) {
                if (rolesInPerson && !rolesInPerson.includes('OWP')) {
                  setRefsOwners([...refsOwners, getOwnerRef(personContext, 'person', refIndex)]);
                  setFieldValueFormik(`people[${refIndex}].isOwner`, 'yes');
                }
                if (
                  currentLegalForm?.label === GbRLegalForm &&
                  getLocaleAllocation(allocation, LANGUAGE) > 25
                ) {
                  if (rolesInPerson && !rolesInPerson.includes('LRP')) {
                    setRefsLegalRepresentatives([
                      ...refsLegalRepresentatives,
                      getLegalRepresentativeRef(personContext, people.length),
                    ]);
                    setFieldValueFormik(`people[${refIndex}].isLegalRepresentative`, 'yes');
                  }
                  if (rolesInPerson && !rolesInPerson.includes('LAP')) {
                    setRefsLoanApplicants([
                      ...refsLoanApplicants,
                      getLoanApplicantRef(personContext, people.length),
                    ]);
                    setFieldValueFormik(`people[${refIndex}].isLoanApplicant`, 'yes');
                  }
                }
              } else {
                setRefsOwners([...refsOwners, getOwnerRef(personContext, 'person', refIndex)]);
                if (
                  currentLegalForm?.label === GbRLegalForm ||
                  currentLegalForm?.label === EKLegalFormLabel ||
                  currentLegalForm?.label === FreelanceLegalFormLabel
                ) {
                  setRefsLegalRepresentatives([
                    ...refsLegalRepresentatives,
                    getLegalRepresentativeRef(personContext, people.length),
                  ]);
                  setRefsLoanApplicants([
                    ...refsLoanApplicants,
                    getLoanApplicantRef(personContext, people.length),
                  ]);
                }
              }
            }
          }
          if (personCompanySelection?.groupId === 0 && level === 1) {
            const personShares = personInContext?.shares.find((s) => s.parent === parentCompany);
            const shareRole = getShareRoles(personShares?.allocation, personShares?.sideDeal);
            const currentShareRole = getShareRoles(allocation, sideDeal);
            if (shareRole.isOwner && !currentShareRole.isOwner)
              removeFromContext(refsOwners, setRefsOwners, personCompanySelection?.id);
          }
        }
      },
      [
        currentLegalForm?.label,
        peopleFormik,
        people,
        parentCompany,
        mainCompany,
        customerProfileId,
        setPeople,
        afterCreate,
        setRefsOwners,
        refsOwners,
        setFieldValueFormik,
        refsBeneficialOwners,
        setRefsBeneficialOwners,
        setRefsLegalRepresentatives,
        refsLegalRepresentatives,
        setRefsLoanApplicants,
        refsLoanApplicants,
        level,
        secondLevelRefID,
        thirdLevelRefID,
      ],
    );

    const handleUseExistingCompany = useCallback(
      async (values: any, role: any) => {
        const { personCompanySelection, allocation, sideDeal } = values;
        const newRoles = [role];
        let dataBody;
        let companyInContext;
        let rolesInCompany;
        if (personCompanySelection?.groupId === 0) {
          companyInContext = companies.find((p) => p.id === personCompanySelection?.id);
          rolesInCompany = getRoleArrayFromPersonCompany(companyInContext as Company);
          const companyShares = companyInContext?.shares.find((s) => s.parent === parentCompany);
          const { isOwner, isBeneficialOwner } = getShareRoles(
            companyShares?.allocation,
            companyShares?.sideDeal,
          );
          const { isOwner: isCurrentOwner, isBeneficialOwner: isCurrentBeneficialOwner } =
            getShareRoles(allocation, sideDeal);
          if (!isOwner && isCurrentOwner && role !== 'OWC') newRoles.push('OWC');
          if (!isBeneficialOwner && isCurrentBeneficialOwner && role !== 'BOC')
            newRoles.push('BOC');
          const dataCompany: BackendNewBusinessData = {
            customer_profile_relation: {
              roles: [...rolesInCompany, ...newRoles],
            },
            shares: [
              {
                parent: parentCompany,
                allocation: getLocaleAllocation(allocation, LANGUAGE),
              },
            ],
          };
          if (
            mainCompany &&
            getLocaleAllocation(allocation, LANGUAGE) <= 50 &&
            dataCompany.shares
          ) {
            dataCompany.shares[0].side_deal = returnBooleanForBackend(sideDeal);
          }
          const { data } = await request.patch(
            `${ApiCaptiq.CUSTOMER_PROFILE_BUSINNESS(customerProfileId!)}${companyInContext?.id}/`,
            dataCompany,
          );
          dataBody = data;
        } else {
          const dataCompany: BackendExistingData = {
            entity: personCompanySelection?.id,
            customer_profile_relation: {
              roles: newRoles,
            },
            shares: [
              {
                parent: parentCompany,
                allocation: getLocaleAllocation(allocation, LANGUAGE),
              },
            ],
          };
          if (
            mainCompany &&
            getLocaleAllocation(allocation, LANGUAGE) <= 50 &&
            dataCompany.shares
          ) {
            dataCompany.shares[0].side_deal = returnBooleanForBackend(sideDeal);
          }
          const { data } = await request.post(
            `${ApiCaptiq.CUSTOMER_PROFILE(customerProfileId!)}add-existing-business/`,
            dataCompany,
          );
          dataBody = data;
        }
        const companyContext = serializeCompanyToContext(dataBody, mainCompany);
        setCreationValues({ ...companyContext, personOrCompany: 'company' });
        setPersonOrCompanyId(companyContext.businessId as string);
        let formikCompanyIndex: number | undefined;
        if (personCompanySelection?.groupId === 0)
          formikCompanyIndex = companiesFormik.findIndex(
            (pf: Company) => pf.id === companyContext.id,
          );
        const refIndex = formikCompanyIndex ?? companiesFormik.length;
        if (personCompanySelection?.groupId !== 0) {
          setCompanies([...companies, companyContext]);
          afterCreate({ ...companyContext, personOrCompany: 'company' });
        }
        if (role === 'OWC') {
          setRefsOwners([...refsOwners, getOwnerRef(companyContext, 'company', refIndex)]);
          if (personCompanySelection?.groupId === 0) {
            setFieldValueFormik(`people[${refIndex}].isOwner`, 'yes');
            const companyShares = companyInContext?.shares.find((s) => s.parent === parentCompany);
            const shareRole = getShareRoles(companyShares?.allocation, companyShares?.sideDeal);
            const currentShareRole = getShareRoles(allocation, sideDeal);
            if (shareRole.isBeneficialOwner && !currentShareRole.isBeneficialOwner)
              removeFromContext(
                refsBeneficialOwners,
                setRefsBeneficialOwners,
                personCompanySelection?.id,
                true,
              );
            setFieldValueFormik(`companies[${refIndex}].shares`, companyContext.shares);
          }
          if (getLocaleAllocation(allocation, LANGUAGE) > 25) {
            if (personCompanySelection?.groupId === 0) {
              if (rolesInCompany && !rolesInCompany.includes('BOC')) {
                companyContext.isBeneficialOwnerLevelOne = 'yes';
                setRefsBeneficialOwners([
                  ...refsBeneficialOwners,
                  getBeneficialOwnerRef(companyContext, 'company', refIndex),
                ]);
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelOne`, 'yes');
              }
            } else {
              companyContext.isBeneficialOwnerLevelOne = 'yes';
              setRefsBeneficialOwners([
                ...refsBeneficialOwners,
                getBeneficialOwnerRef(companyContext, 'company', refIndex),
              ]);
            }
          }
        } else {
          if (level === 1) companyContext.isBeneficialOwnerLevelOne = 'yes';
          if (level === 2) companyContext.isBeneficialOwnerLevelTwo = 'yes';
          if (level === 3) companyContext.isBeneficialOwnerLevelThree = 'yes';
          const boRef = getBeneficialOwnerRef(companyContext, 'company', refIndex);
          if (level === 2 || level === 3) {
            const newBORef: RefBeneficialOwner = JSON.parse(
              JSON.stringify(refsBeneficialOwners[secondLevelRefID as number]),
            );
            if (level === 2) {
              newBORef.children?.push(boRef);
              if (personCompanySelection?.groupId === 0) {
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
                setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelTwo`, 'yes');
              }
            } else if (newBORef && newBORef.children) {
              const thirdLevelChildren = newBORef.children[thirdLevelRefID as number];
              if (thirdLevelChildren && thirdLevelChildren.children) {
                thirdLevelChildren.children.push(boRef);
              }
            }
            setRefsBeneficialOwners([
              ...refsBeneficialOwners.slice(0, secondLevelRefID as number),
              newBORef,
              ...refsBeneficialOwners.slice((secondLevelRefID as number) + 1),
            ]);
            if (personCompanySelection?.groupId === 0) {
              setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
              setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelThree`, 'yes');
            }
            setFieldValueFormik(`companies[${refIndex}].shares`, companyContext.shares);
          } else {
            setRefsBeneficialOwners([...refsBeneficialOwners, boRef]);
            if (personCompanySelection?.groupId === 0) {
              setFieldValueFormik(`people[${refIndex}].isBeneficialOwner`, 'yes');
              setFieldValueFormik(`people[${refIndex}].isBeneficialOwnerLevelOne`, 'yes');
            }
            if (getLocaleAllocation(allocation, LANGUAGE) > 0) {
              if (personCompanySelection?.groupId === 0) {
                if (rolesInCompany && !rolesInCompany.includes('OWC')) {
                  setRefsOwners([...refsOwners, getOwnerRef(companyContext, 'company', refIndex)]);
                  setFieldValueFormik(`people[${refIndex}].isOwner`, 'yes');
                }
              } else
                setRefsOwners([...refsOwners, getOwnerRef(companyContext, 'company', refIndex)]);
            }
          }
          if (personCompanySelection?.groupId === 0 && level === 1) {
            const companyShares = companyInContext?.shares.find((s) => s.parent === parentCompany);
            const shareRole = getShareRoles(companyShares?.allocation, companyShares?.sideDeal);
            const currentShareRole = getShareRoles(allocation, sideDeal);
            if (shareRole.isOwner && !currentShareRole.isOwner)
              removeFromContext(refsOwners, setRefsOwners, personCompanySelection?.id);
          }
        }
      },
      [
        companiesFormik,
        companies,
        parentCompany,
        mainCompany,
        customerProfileId,
        setCompanies,
        afterCreate,
        setRefsOwners,
        refsOwners,
        setFieldValueFormik,
        refsBeneficialOwners,
        setRefsBeneficialOwners,
        level,
        secondLevelRefID,
        thirdLevelRefID,
      ],
    );

    const handleCreatePerson = useCallback(
      async (values: any, role: any) => {
        const {
          title,
          firstName,
          lastName,
          salutation,
          isBeneficialOwner,
          isOwner,
          isLegalRepresentative,
          isLoanApplicant,
          allocation,
          allocationMainCompany,
          sideDeal,
        } = values;
        const roles = [role];
        if (isLegalRepresentative === 'yes') {
          roles.push('LRP');
          if (currentLegalForm?.label === GbRLegalForm) roles.push('LAP');
        }
        if (isBeneficialOwner === 'yes' && role !== 'BOP') roles.push('BOP');
        if (isOwner === 'yes' && role !== 'OWP') roles.push('OWP');
        if (isLoanApplicant === 'yes') roles.push('LAP');
        const dataPerson: BackendNewPersonData = {
          first_name: firstName,
          last_name: lastName,
          salutation,
          title,
          customer_profile_relation: {
            roles,
            legitimacy:
              currentLegalForm?.label === GbRLegalForm ||
              currentLegalForm?.label === EKLegalFormLabel ||
              currentLegalForm?.label === FreelanceLegalFormLabel,
          },
          shares: [
            {
              parent: parentCompany,
              allocation: getLocaleAllocation(allocation, LANGUAGE),
              side_deal:
                getLocaleAllocation(allocation, LANGUAGE) <= 50
                  ? returnBooleanForBackend(sideDeal)
                  : false,
            },
          ],
        };
        if (
          (level !== 1 &&
            getLocaleAllocation(allocationMainCompany, LANGUAGE) <= 50 &&
            getLocaleAllocation(allocationMainCompany, LANGUAGE) >= 0) ||
          getLocaleAllocation(allocationMainCompany, LANGUAGE) > 50
        ) {
          const ownerAllocation = getLocaleAllocation(allocationMainCompany, LANGUAGE);
          dataPerson.shares?.push({
            parent: mainCompany?.id,
            allocation: ownerAllocation,
            side_deal: ownerAllocation > 25,
          });
        }
        const { data } = await request.post(
          ApiCaptiq.CUSTOMER_PROFILE_PERSON(customerProfileId!),
          dataPerson,
        );
        const personContext = serializePersonToContext(data, mainCompany);
        setCreationValues({ ...personContext, personOrCompany: 'person' });
        setPersonOrCompanyId(personContext.personId as string);
        setPeople([...people, personContext]);
        afterCreate({ ...personContext, personOrCompany: 'person' });
        if (role === 'OWP') {
          setRefsOwners([...refsOwners, getOwnerRef(personContext, 'person', people.length)]);
          if (isBeneficialOwner === 'yes') {
            personContext.isBeneficialOwnerLevelOne = 'yes';
            setRefsBeneficialOwners([
              ...refsBeneficialOwners,
              getBeneficialOwnerRef(personContext, 'person', people.length),
            ]);
          }
        } else {
          if (level === 1) personContext.isBeneficialOwnerLevelOne = 'yes';
          if (level === 2) personContext.isBeneficialOwnerLevelTwo = 'yes';
          if (level === 3) personContext.isBeneficialOwnerLevelThree = 'yes';
          const boRef = getBeneficialOwnerRef(personContext, 'person', people.length);
          if (level === 2 || level === 3) {
            const newBORef: RefBeneficialOwner = JSON.parse(
              JSON.stringify(refsBeneficialOwners[secondLevelRefID as number]),
            );
            if (level === 2) {
              newBORef.children?.push(boRef);
            } else if (newBORef && newBORef.children) {
              const thirdLevelChildren = newBORef.children[thirdLevelRefID as number];
              if (thirdLevelChildren && thirdLevelChildren.children) {
                thirdLevelChildren.children.push(boRef);
              }
            }
            setRefsBeneficialOwners([
              ...refsBeneficialOwners.slice(0, secondLevelRefID as number),
              newBORef,
              ...refsBeneficialOwners.slice((secondLevelRefID as number) + 1),
            ]);
          } else {
            setRefsBeneficialOwners([...refsBeneficialOwners, boRef]);
          }
        }
        if (isLoanApplicant === 'yes')
          setRefsLoanApplicants([
            ...refsLoanApplicants,
            getLoanApplicantRef(personContext, people.length),
          ]);
        if (isOwner === 'yes') {
          setRefsOwners([...refsOwners, getOwnerRef(personContext, 'person', people.length)]);
        }
        if (isLegalRepresentative === 'yes') {
          setRefsLegalRepresentatives([
            ...refsLegalRepresentatives,
            getLegalRepresentativeRef(personContext, people.length),
          ]);
        }
        if (currentLegalForm?.label === GbRLegalForm)
          setRefsLoanApplicants([
            ...refsLoanApplicants,
            getLoanApplicantRef(personContext, people.length),
          ]);
      },
      [
        customerProfileId,
        level,
        mainCompany,
        people,
        refsBeneficialOwners,
        refsLegalRepresentatives,
        refsOwners,
        refsLoanApplicants,
        secondLevelRefID,
        setPeople,
        setRefsBeneficialOwners,
        setRefsLegalRepresentatives,
        setRefsOwners,
        setRefsLoanApplicants,
        thirdLevelRefID,
        currentLegalForm?.label,
        parentCompany,
        afterCreate,
      ],
    );

    const handleCreateCompany = useCallback(
      async (values: any, role: any) => {
        const {
          companyName,
          legalForm,
          hgb,
          allocation,
          sideDeal,
          isOwner,
          isBeneficialOwner,
          allocationMainCompany,
        } = values;
        const dataCompany: BackendNewBusinessData = {
          business: companyName,
          hgb_accordance: returnBooleanForBackend(hgb),
          legal_form: legalForm,
          customer_profile_relation: {
            roles: [],
          },
          shares: [
            {
              parent: parentCompany,
              allocation: getLocaleAllocation(allocation, LANGUAGE),
            },
          ],
        };
        if (mainCompany && getLocaleAllocation(allocation, LANGUAGE) <= 50 && dataCompany.shares) {
          dataCompany.shares[0].side_deal = returnBooleanForBackend(sideDeal);
        }
        if (
          (level !== 1 &&
            sideDeal === 'yes' &&
            getLocaleAllocation(allocationMainCompany, LANGUAGE) <= 50 &&
            getLocaleAllocation(allocationMainCompany, LANGUAGE) > 0) ||
          getLocaleAllocation(allocationMainCompany, LANGUAGE) > 50
        ) {
          const ownerAllocation = getLocaleAllocation(allocationMainCompany, LANGUAGE);
          dataCompany.shares?.push({
            parent: mainCompany?.id,
            allocation: ownerAllocation,
            side_deal: ownerAllocation > 25,
          });
        }
        const { data } = await request.post(
          ApiCaptiq.CUSTOMER_PROFILE_BUSINNESS(customerProfileId!),
          dataCompany,
        );
        const companyContext = serializeCompanyToContext(data, mainCompany);
        setCreationValues({ ...companyContext, personOrCompany: 'company' });
        setPersonOrCompanyId(companyContext.businessId as string);
        setCompanies([...companies, companyContext]);
        afterCreate({ ...companyContext, personOrCompany: 'company' });
        if (role === 'OWC') {
          setRefsOwners([...refsOwners, getOwnerRef(companyContext, 'company', companies.length)]);
          if (isBeneficialOwner === 'yes') {
            companyContext.isBeneficialOwnerLevelOne = 'yes';
            setRefsBeneficialOwners([
              ...refsBeneficialOwners,
              getBeneficialOwnerRef(companyContext, 'company', companies.length),
            ]);
          }
        } else {
          if (level === 1) companyContext.isBeneficialOwnerLevelOne = 'yes';
          if (level === 2) companyContext.isBeneficialOwnerLevelTwo = 'yes';
          if (level === 3) companyContext.isBeneficialOwnerLevelThree = 'yes';
          const boRef = getBeneficialOwnerRef(companyContext, 'company', companies.length);
          if (level === 2 || level === 3) {
            const newBORef: RefBeneficialOwner = JSON.parse(
              JSON.stringify(refsBeneficialOwners[secondLevelRefID as number]),
            );
            if (level === 2) {
              newBORef.children?.push(boRef);
            } else if (newBORef && newBORef.children) {
              const thirdLevelChildren = newBORef.children[thirdLevelRefID as number];
              if (thirdLevelChildren && thirdLevelChildren.children) {
                thirdLevelChildren.children.push(boRef);
              }
            }
            setRefsBeneficialOwners([
              ...refsBeneficialOwners.slice(0, secondLevelRefID as number),
              newBORef,
              ...refsBeneficialOwners.slice((secondLevelRefID as number) + 1),
            ]);
          } else {
            setRefsBeneficialOwners([...refsBeneficialOwners, boRef]);
          }
          if (isOwner === 'yes')
            setRefsOwners([
              ...refsOwners,
              getOwnerRef(companyContext, 'company', companies.length),
            ]);
        }
      },
      [
        parentCompany,
        mainCompany,
        level,
        customerProfileId,
        setCompanies,
        companies,
        afterCreate,
        setRefsOwners,
        refsOwners,
        setRefsBeneficialOwners,
        refsBeneficialOwners,
        secondLevelRefID,
        thirdLevelRefID,
      ],
    );

    const createRelatedPaths = useCallback(
      ({
        isBeneficialOwner,
        isOwner,
        isLegalRepresentative,
        isLoanApplicant,
        personOrCompany,
      }: {
        isBeneficialOwner: any;
        isOwner: any;
        isLegalRepresentative?: any;
        isLoanApplicant?: any;
        personOrCompany?: any;
      }) => {
        const paths = createRelatedPathsModal(
          isBeneficialOwner,
          isOwner,
          isLegalRepresentative,
          isLoanApplicant,
          personOrCompany,
          personOrCompanyId,
        );

        paths.map((path) =>
          request
            .post(
              `${ApiCaptiq.LOAN_APPLICATION_URL}${loanApplication?.id}/${path}/change_block_status/`,
              {
                is_component: true,
                status: BLOCK_STATUS_NOT_REVIEWED,
              },
            )
            .catch((err) => console.error(err)),
        );
      },
      [loanApplication?.id, personOrCompanyId],
    );

    const handleCreateOWorBO = useCallback(
      async (values: any) => {
        setSharesError(false);
        setLoadingCreating(true);
        try {
          if (type === 'OW') {
            if (values.createOrOvertake === 'create') {
              if (values.personOrCompany === 'person') await handleCreatePerson(values, 'OWP');
              else await handleCreateCompany(values, 'OWC');
            } else if (values.createOrOvertake === 'overtake') {
              if (values.personOrCompany === 'person') await handleUseExistingPerson(values, 'OWP');
              else await handleUseExistingCompany(values, 'OWC');
            }
          } else if (type === 'BO') {
            if (values.createOrOvertake === 'create') {
              if (values.personOrCompany === 'person') await handleCreatePerson(values, 'BOP');
              else await handleCreateCompany(values, 'BOC');
            } else if (values.createOrOvertake === 'overtake') {
              if (values.personOrCompany === 'person') await handleUseExistingPerson(values, 'BOP');
              else await handleUseExistingCompany(values, 'BOC');
            }
          }
          onClose();
        } catch (error: any) {
          if (
            (error as any).response?.data.shares &&
            (error as any).response?.data.shares[0]?.allocation
          )
            setSharesError(true);
          if (!handleBackendErrors(error as any, enqueueSnackbar, true)) console.error(error);
        } finally {
          setLoadingCreating(false);
        }
      },
      [
        handleCreateCompany,
        handleCreatePerson,
        handleUseExistingPerson,
        handleUseExistingCompany,
        enqueueSnackbar,
        onClose,
        type,
      ],
    );

    const handleOnClose = useCallback(() => {
      onClose();
      resetForm();
      setSharesError(false);
    }, [onClose, resetForm, setSharesError]);

    const handleOnSubmit = useCallback((_: any, { setSubmitting }: any) => {
      setSubmitting(false);
    }, []);

    return (
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        initialErrors={{ createOrOvertake: '', personOrCompany: '' }}
        onSubmit={handleOnSubmit}
      >
        <Modal
          level={level}
          modalType={modalType}
          open={open}
          onClose={handleOnClose}
          loading={loadingCreating}
          title={
            type === 'OW'
              ? level === 1
                ? 'Inhaber(in) hinzufügen'
                : `Inhaber(in) hinzufügen - Niveau ${level}`
              : level === 1
                ? 'Wirtschaftlich Berechtigte(n) hinzufügen'
                : `Wirtschaftlich Berechtigte(n) hinzufügen für ${level}. Beteiligungsebene`
          }
          type={type}
          parentCompany={parentCompany}
          parentName={parentName}
          secondLevelRefID={secondLevelRefID}
          thirdLevelRefID={thirdLevelRefID}
          sharesError={sharesError}
          setSharesError={setSharesError}
          mainCompanyFormik={mainCompanyFormik}
          handleCreateOWorBO={handleCreateOWorBO}
          isEkOrFreelancer={isEkOrFreelancer}
        />
      </Formik>
    );
  },
);

CreateModal.displayName = 'CreateModal';

export default CreateModal;
