import React, { useState, useMemo } from 'react';

import { Button, CircularProgress, Grid, InputLabel } from '@mui/material';

import { FieldFile } from 'components/Field';
import { DEFAULT_CATEGORY, DEFAULT_TYPE } from 'constants/defaults';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import useStyles from './styles';
import { UploadFieldProps } from './types';

const ALLOWED_FORMATS = ['pdf', 'xlsx'];
const FILE_MAX_SIZE = 41943040;
const FILE_UPLOAD = 'FileUpload';

const UploadField = ({
  disabled,
  label,
  value,
  onFilesAdded,
  filename,
  size,
  accept,
  missingDocument,
  slug,
  sectionPath,
  loanId,
  category,
  year,
  fileType,
  personID,
  buttonLabel,
  disabledClass,
  setLoadingUpload,
  loadingUpload,
  setCurrentLoading,
  currentLoading,
  linkedDbId,
  fileSource,
  index,
  legalForm,
  hgbAccordance,
  labelRef,
  id,
  fileIdNew,
  fileId,
}: UploadFieldProps) => {
  const styles = useStyles();
  const [completed, setCompleted] = useState(0);
  const [error, setError] = useState(false);
  const [newDocument, setNewDocument] = useState<File | null>(null);

  const fileName = useMemo(() => {
    if (filename) return filename;
    if (value) {
      const filePathArr = value.split('/');
      if (filePathArr.length < 1) return '';
      return filePathArr[filePathArr.length - 1];
    }
    return '';
  }, [filename, value]);

  const handleFiles = async (files: FileList) => {
    const currentFile = files[0];
    if (
      currentFile?.name &&
      ALLOWED_FORMATS.includes(currentFile.name.split('.').pop()!.toLowerCase()) &&
      currentFile.size <= FILE_MAX_SIZE
    ) {
      if (missingDocument) {
        setNewDocument(currentFile);
      } else {
        await saveFile(currentFile);
        setError(false);
      }
    } else setError(true);
  };

  const handleRemoveFile = async () => {
    const formData = new FormData();
    formData.append('file_name', fileName);
    formData.append('slug', slug);
    formData.append('section_path', sectionPath);
    formData.append('loan_application_id', loanId);
    if (fileIdNew || fileId) formData.append('file_id', String(fileIdNew || fileId));
    formData.append('file_source', fileSource || FILE_UPLOAD);
    try {
      await request.post(`${ApiCaptiq.FILE_UPLOAD_URL}remove_file/`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      onFilesAdded('');
    } catch (err) {
      console.error(err);
    }
  };

  const saveMissingDocument = async () => {
    if (newDocument) {
      const formData = new FormData();
      formData.append('doc_file', newDocument);
      try {
        setLoadingUpload(true);
        const response = await request.patch(
          `${ApiCaptiq.SOLARIS_MISSING_DOCUMENT}${missingDocument}/`,
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: (progressEvent: any) => {
              const percentCompleted = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setCompleted(percentCompleted);
            },
          },
        );
        setCurrentLoading(index);
        onFilesAdded(response.data.doc_file, response.data, true);
      } catch (e) {
        console.error(e);
        setLoadingUpload(false);
      }
    } else if (onFilesAdded) onFilesAdded('');
  };

  const saveFile = async (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('datafile', file);
      formData.append('slug', slug);
      formData.append('section_path', sectionPath);
      formData.append('loan_application_id', loanId);
      if (category !== undefined) {
        formData.append('category', category || DEFAULT_CATEGORY);
        formData.append('file_type', fileType || DEFAULT_TYPE);
      }
      if (fileType === 'HGB' || fileType === 'Legal Form') {
        if (legalForm) {
          formData.append('legal_form', legalForm);
        }
        if (hgbAccordance === true || hgbAccordance === false) {
          formData.append('hgb_accordance', String(hgbAccordance));
        }
      }
      if (year !== undefined) {
        formData.append('year', String(year));
      }
      if (linkedDbId || personID) formData.append('linked_db_id', linkedDbId || personID);
      if (personID) formData.append('loan_applicant_person', String(personID));
      try {
        const { data } = await request.post(ApiCaptiq.FILE_UPLOAD_URL, formData, {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            setCompleted(percentCompleted);
          },
        });
        const { datafile, filename: fileNameData, datafile_name, id: idData, file_source } = data;
        onFilesAdded(datafile, fileNameData, datafile_name, idData, file_source);
      } catch (e) {
        console.error(e);
      }
    } else if (onFilesAdded) onFilesAdded('');
  };

  const renderLabel = () =>
    label && (
      <Grid item sm={12} md={5}>
        <InputLabel htmlFor={id} ref={labelRef}>
          {label}
        </InputLabel>
      </Grid>
    );

  const renderButtonMissingDocument = () =>
    !fileName ? (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={saveMissingDocument}
        disabled={!newDocument || loadingUpload}
        classes={{ disabled: disabledClass }}
      >
        {currentLoading === index && (
          <CircularProgress color="primary" size={24} className={styles.buttonProgress} />
        )}
        {buttonLabel}
      </Button>
    ) : (
      <Button variant="outlined" className={styles.buttonAnswerSent} size="small" disableRipple>
        Antwort gesendet
      </Button>
    );

  return (
    <div className={styles.container}>
      {renderLabel()}
      <FieldFile
        fileName={fileName!}
        value={value}
        accept={accept}
        size={size}
        completed={!!value}
        percentage={completed}
        disabled={disabled}
        onRemove={handleRemoveFile}
        onUpload={handleFiles}
      />
      {error && (
        <div>
          <span className={styles.errorText}>
            Die Dateierweiterung wird nicht unterstützt oder die Datei ist zu groß.
          </span>
        </div>
      )}
      {!!missingDocument && renderButtonMissingDocument()}
    </div>
  );
};

export default UploadField;
