import React, { memo, useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Box,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { DoneAllRounded, ExpandMoreRounded } from '@mui/icons-material';

import { useCustomerData } from 'context/customer-context';
import { getBlockComplete, getHGBDocumentsSlugs } from 'utils/dataHandlers/mapHGBBlockSlugs';
import InfoBoxHGB from 'components/InfoBoxHGB';
import { useBlockData } from 'context/block-context';
import { useFinancingDocuments } from 'service/queries/useFinancingDocuments';
import { useDataContext } from 'context/data-context';
import DocumentForm from './components/DocumentForm';
import useStyles from './styles';
import { CURRENT_YEAR } from './utils';

const FinancingDocuments = memo(
  ({
    customColors,
    expanded,
    setActivePanel,
    isReview = false,
    isEditing = true,
    extraInformation,
    formRef = null,
  }: {
    customColors: any;
    expanded: any;
    setActivePanel: any;
    isReview?: boolean;
    isEditing?: boolean;
    extraInformation: any;
    formRef?: any;
  }) => {
    const { loanApplication } = useDataContext();
    const { balancingForms } = useCustomerData();
    const { setStaticFormikValues } = useBlockData();
    const classes = useStyles();

    const { hgb, loanId, legalForm } = extraInformation;
    const { data: stepData, isLoading, isFetching } = useFinancingDocuments(loanId, legalForm, hgb);
    const queryClient = useQueryClient();
    const [dataStructure, setDataStructure] = useState<any>(null);
    const [initialValues, setInitialValues] = useState<any>({});
    const [formStructure, setFormStructure] = useState<any>(null);

    const getInitialValues = useCallback((newDataStructure: any) => {
      let startValues = {};
      newDataStructure?.forEach((block: any) => {
        startValues = { ...startValues, ...block.startingValues };
      });
      setInitialValues(startValues);
    }, []);

    const getYearInHGBlock = useCallback(
      (block: any, index: number) => {
        const question = CURRENT_YEAR[loanApplication?.hgb_version!] - index;
        return question;
      },
      [loanApplication?.hgb_version],
    );

    const getFormStructure = useCallback((newDataStructure: any) => {
      let newStructure: any = {};
      newDataStructure?.forEach((block: any) => {
        newStructure = { ...newStructure, ...block.metaData };
      });
      setFormStructure(newStructure);
    }, []);

    const getDataStructure = useCallback(
      (block: any) => {
        const newStructure: any = [];

        const blockfields: any = {};
        block.instances.forEach((b: any) => {
          b.questions.forEach((q: any) => {
            if (q.title !== 'Year display')
              q.instances.forEach((i: any) => {
                i.fields.forEach((f: any) => {
                  blockfields[f.slug] = {
                    value: f.value,
                    blockSlug: b.section_path,
                    questionSlug: q.slug,
                    fieldMetada: f,
                  };
                });
              });
          });
        });
        block.instances.forEach((instance: any, index: number) => {
          const blockValues = getHGBDocumentsSlugs(index + 1, hgb, legalForm);
          const baseStructure: any = {};
          Object.keys(blockValues).forEach((k: any, idx: number) => {
            if (blockfields[k]) {
              blockValues[k] = blockfields[k].value ?? '';
              baseStructure[`b${index + 1}_field_f${idx + 1}`] = { name: k, ...blockfields[k] };
            }
          });
          const year = getYearInHGBlock(instance, index);
          newStructure.push({
            year,
            startingValues: blockValues,
            metaData: baseStructure,
          });
        });
        setDataStructure(newStructure);
        return newStructure;
      },
      [getYearInHGBlock, hgb, legalForm],
    );

    useEffect(() => {
      const blockData = stepData?.instances[0]?.blocks[0];
      if (blockData) {
        const newDataStructure = getDataStructure(blockData);
        getInitialValues(newDataStructure);
        getFormStructure(newDataStructure);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepData]);

    useEffect(() => {
      queryClient.invalidateQueries({ queryKey: ['financingDocuments', legalForm, hgb, loanId] });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canShowNextYear = useCallback(
      (year: number, blockId: number) => Number(dataStructure[blockId]?.year) >= year,
      [dataStructure],
    );

    const isCompleted = useCallback(
      (values: any) => {
        const currentYear = CURRENT_YEAR[loanApplication?.hgb_version!];
        let state = getBlockComplete(
          values,
          formStructure,
          1,
          currentYear === extraInformation.foundation,
          extraInformation.hgb,
        );
        if (
          !canShowNextYear(extraInformation.foundation, 1) ||
          values[formStructure[`b${1}_field_f1` as any]?.name] === 'ja'
        )
          return state;
        state =
          state &&
          getBlockComplete(
            values,
            formStructure,
            2,
            currentYear - 1 === extraInformation.foundation,
            extraInformation.hgb,
          );
        if (
          !canShowNextYear(extraInformation.foundation, 2) ||
          values[formStructure[`b${2}_field_f1` as any]?.name] === 'ja'
        )
          return state;
        state =
          state &&
          getBlockComplete(
            values,
            formStructure,
            3,
            currentYear - 2 === extraInformation.foundation,
            extraInformation.hgb,
          );
        if (
          !canShowNextYear(extraInformation.foundation, 3) ||
          values[formStructure[`b${3}_field_f1` as any]?.name] === 'ja'
        )
          return state;
        state =
          state &&
          getBlockComplete(
            values,
            formStructure,
            4,
            currentYear - 3 === extraInformation.foundation,
            extraInformation.hgb,
          );
        return state;
      },
      [
        canShowNextYear,
        extraInformation.foundation,
        extraInformation.hgb,
        formStructure,
        loanApplication?.hgb_version,
      ],
    );

    const renderIsCompleteStatus = (completed: boolean) => {
      if (completed) {
        return (
          <Chip
            className={classes.doneTick}
            icon={<DoneAllRounded style={{ color: '#fff' }} fontSize="inherit" />}
            label="KOMPLETT"
            size="small"
          />
        );
      }

      return <Chip style={{ marginLeft: 15 }} label="ERFORDERLICH" size="small" />;
    };

    const handleSubmit = useCallback(
      (values: any) => {
        setStaticFormikValues({ ...values });
      },
      [setStaticFormikValues],
    );

    if (
      balancingForms &&
      formStructure &&
      dataStructure &&
      (Object.keys(formStructure).length === 0 ||
        Object.keys(dataStructure).length === 0 ||
        Object.keys(balancingForms).length === 0)
    )
      return null;

    if (isReview) {
      return isLoading ||
        isFetching ||
        dataStructure === null ||
        Object.keys(initialValues).length === 0 ||
        formStructure === null ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress color="primary" size={20} />
        </Box>
      ) : (
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          <DocumentForm
            dataStructure={formStructure}
            extraInformation={extraInformation}
            blocks={dataStructure}
            isReview
            isEditing={isEditing}
          />
        </Formik>
      );
    }

    return (
      <>
        {isLoading ||
        isFetching ||
        dataStructure === null ||
        Object.keys(initialValues).length === 0 ||
        formStructure === null ? (
          <AccordionSummary expandIcon={<ExpandMoreRounded />} className={classes.stickyHeader}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress color="primary" size={20} />
            </Box>
          </AccordionSummary>
        ) : (
          <div style={{ marginBottom: 10, marginTop: 10 }}>
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Accordion
                  style={{ backgroundColor: customColors.blockBackgroundColor }}
                  expanded={expanded === 'dokumente_pro_aus_hgb'}
                  key={'dokumente_pro_aus_hgb'}
                  onChange={setActivePanel('dokumente_pro_aus_hgb')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    className={classes.stickyHeader}
                  >
                    <Typography className={classes.title}>
                      {stepData?.instances[0]?.blocks[0]?.title}
                    </Typography>
                    {renderIsCompleteStatus(isCompleted(values))}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={5}>
                      <Grid item xs={12} className={classes.alertHGB}>
                        <InfoBoxHGB hgb={hgb} />
                      </Grid>
                      <Grid item xs={12}>
                        <DocumentForm
                          dataStructure={formStructure}
                          extraInformation={extraInformation}
                          blocks={dataStructure}
                          isEditing={isEditing}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
            </Formik>
          </div>
        )}
      </>
    );
  },
);

FinancingDocuments.displayName = 'FinancingDocuments';

export default FinancingDocuments;
