import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
  },
  container: {
    flex: 1,
  },
  buttonAnswerSent: {
    backgroundColor: '#fff',
    borderColor: '#6DA618',
    color: '#6DA618',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
