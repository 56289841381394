import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import {
  DEFAULT_BLOCK_BACKGROUND_COLOR,
  DEFAULT_TAB_BACKGROUND_COLOR,
  DEFAULT_MAIN_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_LANGUAGE,
  DEFAULT_COMPLETE_COLOR,
} from 'constants/design';
import footerDefaultImage from 'images/footer.png';
import logoDefaultImage from 'images/logo.png';
import faviconDefaultImage from 'images/favicon.png';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import { serializeCustomerProfileToContext } from 'utils/dataHandlers';
import { CustomerProfileIdType } from 'context/customer-context/constants';
import { B2C_ROLE } from 'constants/defaults';
import { UserDataI, tipsterDataI } from './types';
import { initialValues } from './constants';

const AuthContext = createContext(initialValues);

interface AuthProviderI {
  children: React.ReactNode;
}

const AuthProvider = (props: AuthProviderI) => {
  const [mainColor, setMainColor] = useState(DEFAULT_MAIN_COLOR);
  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_BACKGROUND_COLOR);
  const [mainLogo, setMainLogo] = useState(logoDefaultImage);
  const [initialLanguage, setInitialLanguage] = useState(DEFAULT_LANGUAGE);
  const [isTipster, setIsTipster] = useState(false);
  const [favicon, setFavicon] = useState(faviconDefaultImage);
  const [blockBackgroundColor, setBlockBackgroundColor] = useState(DEFAULT_BLOCK_BACKGROUND_COLOR);
  const [tabBackgroundColor, setTabBackgroundColor] = useState(DEFAULT_TAB_BACKGROUND_COLOR);
  const [stepColor, setStepColor] = useState(DEFAULT_MAIN_COLOR);
  const [checkboxColor, setCheckboxColor] = useState(DEFAULT_MAIN_COLOR);
  const [buttonColor, setButtonColor] = useState(DEFAULT_MAIN_COLOR);
  const [selectionColor, setSelectionColor] = useState(DEFAULT_MAIN_COLOR);
  const [completeBlockColor, setCompleteBlockColor] = useState(DEFAULT_COMPLETE_COLOR);
  const [footerImage, setFooterImage] = useState(footerDefaultImage);
  const [sliderColor, setSliderColor] = useState(null);
  const [fontFamily, setFontFamily] = useState('Barlow');
  const [customFontFamily, setCustomFontFamily] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const loanID = urlParams.get('next')?.split('?loan=')[1];
    const tab = urlParams.get('tab');
    const customerID = urlParams.get('customer');
    if (tab && loanID && customerID) {
      localStorage.setItem('openLoanID', loanID as string);
      localStorage.setItem('openLoanTab', tab as string);
      localStorage.setItem('customerID', customerID as string);
    }
    async function loginUserBCA() {
      try {
        setLoadingUser(true);
        const { data } = await request.get(ApiCaptiq.GET_PROFILE);
        if (data.role === B2C_ROLE && !data.approved) {
          window.location.href = '/de/admin';
        }
        login(data);
      } catch (error) {
        logout();
      } finally {
        setLoadingUser(false);
      }
    }
    if (!window.location.pathname.includes('logout')) loginUserBCA();
  }, []);

  const login = (userData: UserDataI) => {
    localStorage.setItem('user', userData.email);
    localStorage.setItem('role', userData.role);
    localStorage.setItem('token', userData.token.access);
    localStorage.setItem('refresh', userData.token.refresh);
    localStorage.removeItem('userWarned');
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('openWarning');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('userWarned');
    localStorage.removeItem('isBCA');
  };

  const isCustomerProfileActivated = useCallback(
    async (customerID: CustomerProfileIdType): Promise<boolean | undefined> => {
      try {
        const { data } = await request.get(ApiCaptiq.CUSTOMER_PROFILE(customerID!));
        const {
          customerProfileData: { customerProfileStatus },
        } = serializeCustomerProfileToContext(data);
        return customerProfileStatus;
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  const persistData = (userData: UserDataI) => {
    localStorage.setItem('persist_data', JSON.stringify(userData));
  };

  const clearPersistData = () => {
    localStorage.removeItem('persist_data');
  };

  const getPersistData = () => JSON.parse(localStorage.getItem('persist_data') || '{}');

  const getUser = () => localStorage.getItem('user');

  const getRole = () => localStorage.getItem('role');

  const getRefreshToken = () => localStorage.getItem('refresh');

  const isAuthenticated = () => !!localStorage.getItem('user');

  const setTipsterData = (tipsterData: tipsterDataI) => {
    localStorage.setItem('tipster_employee', tipsterData.tipster_employee ?? '');
    localStorage.setItem('tipster_partner', tipsterData.tipster_partner ?? '');
    localStorage.setItem('tipster_pool', tipsterData.tipster_pool ?? '');
    localStorage.setItem('tipster_token', tipsterData.tipster_token ?? '');
  };

  const getTipsterData = () => ({
    tipster_partner: localStorage.getItem('tipster_partner'),
    tipster_employee: localStorage.getItem('tipster_employee'),
    tipster_pool: localStorage.getItem('tipster_pool'),
    tipster_token: localStorage.getItem('tipster_token'),
  });

  const getCustomColors = () => ({
    blockBackgroundColor,
    buttonColor,
    tabBackgroundColor,
    stepColor,
    completeBlockColor,
  });

  const blocksStatus = ['test'];

  const addRequiredField = (item: string) => blocksStatus.push(item);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        getUser,
        getRole,
        getTipsterData,
        setTipsterData,
        persistData,
        clearPersistData,
        getPersistData,
        blocksStatus,
        addRequiredField,
        mainLogo,
        mainColor,
        backgroundColor,
        initialLanguage,
        isTipster,
        favicon,
        blockBackgroundColor,
        tabBackgroundColor,
        stepColor,
        checkboxColor,
        buttonColor,
        getCustomColors,
        selectionColor,
        completeBlockColor,
        footerImage,
        sliderColor,
        fontFamily,
        customFontFamily,
        setMainLogo,
        setMainColor,
        setBackgroundColor,
        setInitialLanguage,
        setIsTipster,
        setFavicon,
        setBlockBackgroundColor,
        setTabBackgroundColor,
        setStepColor,
        setCheckboxColor,
        setButtonColor,
        setSelectionColor,
        setCompleteBlockColor,
        setFooterImage,
        setSliderColor,
        setFontFamily,
        setCustomFontFamily,
        loadingUser,
        isCustomerProfileActivated,
        getRefreshToken,
        isViewMode,
        setIsViewMode,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, AuthContext, useAuth };
