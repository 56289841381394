import classNames from 'classnames';
import Lottie from 'lottie-react-web';

import CheckAnimation from 'animation/check.json';
import { useEditorData } from 'context/editor-context';
import { PreviewFieldProps } from './types';
import useStyles from './styles';

const PreviewField = ({ value, blockSlug, slug, innerRef }: PreviewFieldProps) => {
  const styles = useStyles();
  const { handleOpen } = useEditorData();

  const customHandleOpen = () => {
    handleOpen(blockSlug, slug);
  };

  return (
    <div
      ref={innerRef}
      className={classNames(styles.container, value && styles.containerPreviewCheck)}
      onClick={customHandleOpen}
      data-testid="preview-field"
    >
      <div className={styles.previewContainer}>
        <div className={styles.contentContainer} dangerouslySetInnerHTML={{ __html: value }} />
        {value && (
          <div className={styles.iconContainer}>
            <Lottie
              width={30}
              height={30}
              options={{
                animationData: CheckAnimation,
                loop: false,
              }}
              speed={2}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewField;
