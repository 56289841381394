import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    border: '2px dashed rgb(214, 213, 213)',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    gap: '8px',
    position: 'relative',
    '&:hover': {
      borderColor: '#999',
    },
  },
  fileName: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  iconDelete: {
    fontSize: 22,
    color: '#C8C8C8',
    '&:hover': {
      color: '#b70000',
    },
  },
  download: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
  },
  downloadButton: {
    fontSize: '13px',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  downloadIcon: {
    fontSize: '14px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  highlight: {
    borderColor: theme.palette.primary.main,
  },
  check: {
    position: 'absolute',
    top: '50%',
    right: '16px',
    transform: 'translateY(-50%)',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    color: '#888',
    fontSize: 16,
  },
  progress: {
    width: '100%',
    maxWidth: 500,
    marginTop: 10,
  },
  fileInput: {
    display: 'none',
  },
}));

export default useStyles;
