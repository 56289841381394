import React, { memo } from 'react';
import classNames from 'classnames';

import { Grid, Typography } from '@mui/material';

import CaptiqTooltip from 'components/CaptiqTooltip';
import { LabeledGridProps } from './types';
import useStyles from './styles';

const LabeledGrid = ({
  label,
  children,
  justify = 'flex-start',
  alignItems = 'flex-start',
  xs = 12,
  smLabel = 4,
  smChild = 6,
  smExtra = 2,
  spacing = 1,
  required = false,
  tooltip,
  positionTooltip = 'top',
  titleTooltip = undefined,
  customClassLabel = '',
  customClassChild = '',
  customStyle,
  extraComponent,
}: LabeledGridProps) => {
  const styles = useStyles();

  return (
    <Grid
      container
      item
      spacing={spacing}
      justifyContent={justify}
      alignItems={alignItems}
      className={customStyle}
    >
      {label ? (
        <Grid item xs={xs} sm={smLabel} className={styles.fieldLabel}>
          <div className={styles.typography}>
            <Typography
              className={
                customClassLabel === undefined || customClassLabel === ''
                  ? styles.label
                  : customClassLabel
              }
              variant="body2"
            >
              {label}: {required ? ' *' : ''}
            </Typography>
            {tooltip ? (
              <div className={styles.tooltip}>
                <CaptiqTooltip content={tooltip} position={positionTooltip} title={titleTooltip} />
              </div>
            ) : null}
          </div>
        </Grid>
      ) : null}
      {children ? (
        <>
          <Grid item xs={xs} sm={smChild} className={classNames(styles.input, customClassChild)}>
            {children}
          </Grid>
          {extraComponent ? (
            <Grid item xs={xs} sm={smExtra} className={styles.input}>
              {extraComponent}
            </Grid>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};

export default memo(LabeledGrid);
