import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 200,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #cacaca',
    borderRadius: 5,
    position: 'relative',
  },
  previewContainer: {
    padding: 10,
    height: '100%',
    width: '100%',
    overflow: 'scroll',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
  },
  containerPreviewCheck: {
    borderColor: theme.palette.primary.main,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  contentContainer: {
    width: '100%',
  },
}));

export default useStyles;
