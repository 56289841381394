import { createContext, useContext, useState, useCallback } from 'react';

import { AddBlockProps, Current, EditorDataProviderProps, EditorValues } from './types';
import { initialState } from './constants';

const EditorData = createContext(initialState);

const EditorDataProvider = ({ children }: EditorDataProviderProps) => {
  const [editorValues, setEditorValues] = useState<EditorValues>({});
  const [props, setProps] = useState<EditorValues>({});
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState<Current>({
    blockSlug: '',
    slug: '',
  });

  const addBlock: AddBlockProps = useCallback(
    (
      blockSlug,
      dataSlug,
      value,
      questions,
      onChange,
      loanId,
      title,
      customColors,
      disabled,
      forceUpdate = () => {},
    ) => {
      setEditorValues((prevState) => ({
        ...prevState,
        [blockSlug]: {
          ...(prevState[blockSlug] || {}),
          [dataSlug]: value,
        },
      }));

      setProps((prevState) => ({
        ...prevState,
        [blockSlug]: {
          ...(prevState[blockSlug] || {}),
          [dataSlug]: {
            questions,
            blockSlug,
            onChange,
            slug: dataSlug,
            loanId,
            title,
            customColors,
            disabled,
            forceUpdate,
          },
        },
      }));
    },
    [setEditorValues, setProps],
  );

  const handleOpen = (blockSlug: string, slug: string) => {
    setCurrent({ blockSlug, slug });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrent({
      blockSlug: '',
      slug: '',
    });
  };

  const value = {
    editorValues,
    current,
    open,
    props,
    addBlock,
    handleOpen,
    handleClose,
  };

  return <EditorData.Provider value={value}>{children}</EditorData.Provider>;
};

export const useEditorData = () => {
  const context = useContext(EditorData);
  if (context === undefined) {
    throw new Error('useEditorData must be used within a EditorDataProvider');
  }
  return context;
};

export default EditorDataProvider;
